import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useParams } from "react-router-dom"
import { getPrice, getProduct, setNewPrice, setPrice, setProduct, updateProduct } from "../../../../store/ProductsStore/products"
import Preloader from "../../../../components/Preloader"
import Switcher from "../../../../components/Switcher"
import { gatewayURL } from "../../../../store/api"
import Logo from "../../../../assets/images/logo.svg"
import axios from "axios"

const ProductPage = () => {

    const {id} = useParams()

    const product = useSelector((state) => state.products.product)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Продукция'))
        dispatch(setPageTitleBack({name: 'Продукция', link: `/app/settings/products`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getProduct(id))
    }, [id, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setProduct(null))
            dispatch(setPrice(null))
        }
    }, [dispatch])

    if (product === null) {
        return <Preloader />
    }

    return (
        <>
            <TitleBlock product={product} />
            <PriceBlock productID={product.ID} />
            <MediaBlock productID={product.ID} />
        </>
    )
}

export default ProductPage

const TitleBlock = ({product}) => {

    const dispatch = useDispatch()

    const [active, setActive] = useState(true)
    const [title, setTitle] = useState("")
    const [article, setArticle] = useState("")
    const [comment, setComment] = useState("")

    useEffect(() => {
        setActive(product.active ?? false)
        setTitle(product.title)
        setArticle(product.article ?? "")
        setComment(product.comment ?? "")
    }, [product])

    const handlerSubmit = (e) => {
        e.preventDefault()
        let data = {
            title,
            comment,
            article,
            active
        }
        dispatch(updateProduct(product.ID, data))
    }

    const handlerSwitch = (e) => {
        setActive(e.target.checked)
    }

    return (
        <div className="product-title">
            <div className="product-title-image">
                <Preview image={product.preview ?? null} alt={product.title} productID={product.ID} />
            </div>
            <div className="product-title-form" style={{flexGrow: 1}}>
                <div className="mb-12">
                    <Switcher active={active} handler={handlerSwitch} label="Активный" />
                </div>

                <div className="mb-24">
                    <form onSubmit={handlerSubmit}>
                        <div className="form-row">
                            <label htmlFor="title">
                                <div className="form-label">Название</div>
                                <input id="title"
                                        name="title"
                                        type="text"
                                        className="text-field"
                                        required
                                        value={title}
                                        onChange={e => setTitle(e.target.value)} />
                            </label>
                        </div>

                        <div className="form-row">
                            <label htmlFor="article">
                                <div className="form-label">Артикул</div>
                                <input id="article"
                                        name="article"
                                        type="text"
                                        className="text-field"
                                        required
                                        value={article}
                                        onChange={e => setArticle(e.target.value)} />
                            </label>
                        </div>

                        <div className="form-row mb-24">
                            <label htmlFor="comment">
                                <div className="form-label">Комментарий</div>
                                <textarea id="comment"
                                        name="comment"
                                        type="text"
                                        className="text-field"
                                        value={comment}
                                        onChange={e => setComment(e.target.value)} />
                            </label>
                        </div>
                        <div>
                            <button className="button">Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const Preview = ({image, alt, productID}) => {

    const [img, setImg] = useState(null)

    useEffect(() => {
        if (image) {
            setImg(`${gatewayURL}/api/v1/b/images/${productID}/${image}`)
        } else {
            setImg(Logo)
        }
    }, [image, productID])
    
    return (
        <>
            <img src={img} alt={alt} />
            <UploadPreview productID={productID} />
        </>
    )
}

const UploadPreview = ({productID}) => {
    const token = useSelector((state) => state.auth.token)

    const [file, setFile] = useState()

    function handleChange(event) {
      setFile(event.target.files[0])
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if (file) {
            const url = `${gatewayURL}/api/v1/b/images/upload`;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            formData.append('productID', productID);
            const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response.data);
                dispatch(getProduct(productID))
            });
        }
    }, [file, token, productID, dispatch])

    return (
        <form>
          <input type="file" className="text-field" onChange={handleChange}/>
        </form>
    )
}

const MediaBlock = ({productID}) => {
    return (
        <div>Media later</div>
    )
}

const PriceBlock = ({productID}) => {

    const dispatch = useDispatch()

    const price = useSelector((state) => state.products.price)

    useEffect(() => {
        dispatch(getPrice(productID))
    }, [productID, dispatch])

    return (
        <div>
            <h2>РРЦ {price ? ` - ${price.price} руб.` : "не задана"}</h2>
            <PriceForm productID={productID} />
        </div>
    )
}

const PriceForm = ({productID}) => {

    const dispatch = useDispatch()
    
    const [price, setPrice] = useState("")

    const handlerSubmit = (e) => {
        e.preventDefault()
        dispatch(setNewPrice(
            productID,
            {
                price: parseInt(price),
            }
        ))
    }

    return (
        <div className="mb-24" style={{width: 300}}>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="price">
                        <div className="form-label">Установить РРЦ</div>
                        <input id="price"
                                name="price"
                                type="number"
                                className="text-field"
                                required
                                value={price}
                                onChange={e => setPrice(e.target.value)} />
                    </label>
                </div>
                <div>
                    <button className="button">Сохранить</button>
                </div>
            </form>
        </div>
    )
}