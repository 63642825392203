import "../style.css"
import HeaderWrapper from "../Header"
import BottomSide from "../BottomSide"

const MainWrapper = ({children}) => {
    return (
        <div className="wrap-root">
            <div className="wrap-root-main">
                <HeaderWrapper />
                <div className="wrapper">
                    <div className="wrapper-page">
                        {children}
                    </div>
                </div>
            </div>
            <div className="wrap-root-side">
                <BottomSide />
            </div>
        </div>
    )
}

export default MainWrapper