import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { ChangeLinked, GetLinkedProducts, getSeller, getSellerLinked, setShowOnlyUnlinked } from "../../../../store/SettingsStore/seller"
import Preloader from "../../../../components/Preloader"
import ModalFS from "../../../../components/Modal/fullScreen"
import { gatewayURL } from "../../../../store/api"
import { getProducts } from "../../../../store/ProductsStore/products"
import Switcher from "../../../../components/Switcher"

const SellerCompanyPage = () => {

    const dispatch = useDispatch()

    const {id, cid} = useParams()

    const seller = useSelector((state) => state.seller.seller)

    useEffect(() => {
        dispatch(getSeller(id))
        dispatch(getSellerLinked(id))
        dispatch(GetLinkedProducts(id, cid))
    },[dispatch, id, cid])

    if (!seller) {
        return <Preloader />
    }
    return (
        <>
            <TopBlock cid={cid}/>
            <SCProducts id={parseInt(id)} cid={parseInt(cid)} />
        </>
    )
}

export default SellerCompanyPage

const TopBlock = ({cid}) => {
    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)
    const linked = useSelector((state) => state.seller.linked)

    const [c, setC] = useState(null)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(seller.name))
        dispatch(setPageTitleBack({name: seller.name, link: `/app/settings/sellers/${seller.ID}`}))
    }, [dispatch, seller])

    useEffect(() => {
        linked.forEach(element => {
            if (element.ID === parseInt(cid)) {
                setC(element)
            }
        });
    }, [cid, linked])

    if (!c) {
        return null
    }

    return (
        <h2>{c.name}</h2>
    )
}

const SCProducts = ({id, cid}) => {
    const dispatch = useDispatch()

    const linkedProducts = useSelector((state) => state.seller.linkedProducts)
    const unlinked = useSelector((state) => state.seller.showOnlyUnlinked)

    const [s, setS] = useState(false);
    const [p, setP] = useState(null)
    const [pid, setPid] = useState(null)

    const handlerClose = () => {
        setS(false)
        setP(null)
        setPid(null)
    }

    const handlerOpen = (data) => {
        setPid(data)
    }

    const unlinkedHandler = () => {
        dispatch(setShowOnlyUnlinked(!unlinked))
    }

    useEffect(() => {
        linkedProducts.forEach(element => {
            if (element.product.ID === parseInt(pid)) {
                setP(element)
                setS(true)
            }
        });
    }, [pid, linkedProducts])

    return (
        <>
            <h3>Продукты</h3>
            <Switcher active={unlinked} label="Только не связанные товары" handler={unlinkedHandler} />
            <table className="table">
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Состояние у компании</th>
                        <th>Связь</th>
                    </tr>
                </thead>
                <tbody>
                    {linkedProducts.map((e, i) => <SCProductRow key={i} data={e} handler={handlerOpen} unlinked={unlinked} />)}
                </tbody>
            </table>
            {s ? <ModalFS close={handlerClose} body={<SCProduct product={p} id={id} cid={cid} />} /> : null}
        </>
    )
}

const SCProductRow = ({data, handler, unlinked}) => {

    if (unlinked && data.link) {
        return null
    }

    return(
       <tr onClick={() => handler(data.product.ID)}>
           <td>{data.product.title}</td>
           <td><div className={data.product.active ? "dot final" : "dot red"}></div></td>
           <td><div className={data.link ? "dot final" : "dot red"}></div></td>
       </tr>
   )

}

const SCProduct = ({product, id, cid}) => {    
    const [image, setImage] = useState(null)
    const [urls, setUrls] = useState([])

    useEffect(() => {
        if (product.product.preview) {
            let imgData = product.product.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [product.product.preview])

    useEffect(() => {
        if (product.urls) {
            setUrls(product.urls)
        }
    }, [product.urls])

    return (
        <>
            <div style={{display: 'flex'}}>
                <div style={{flex: '0 0 50%'}}>
                    <h3>{product.product.title}</h3>
                    <img style={{maxWidth: 300}} src={image} alt={product.product.title} />
                    <UrlsCP urls={urls} />
                </div>
                <div style={{flex: '0 0 50%'}}>
                    <h3>Продукция производителя</h3>
                    <LinkerSCP link={product.link ?? {ID: 0, BProductID: 0}} pid={product.product.ID} id={id} cid={cid} />
                </div>
            </div>
        </>
    )
}

const UrlsCP = ({urls}) => {
    return (
        <>
            <h3>Ссылки</h3>
            {urls.map((e, i) => <UrlCP url={e} key={i} />)}
        </>
    )
}

const UrlCP = ({url}) => {

    if (!url.urls || url.urls.length === 0) {
        return null
    }

    return (
        <>
            <h4>{url.shop}</h4>
            <ul>
            {url.urls.map((e, i) => <li key={i}><a target="_blank" rel="noreferrer" href={e}>{e}</a></li>)}
            </ul>
        </>
    )
}

const LinkerSCP = ({link, pid, id, cid}) => {
    const products = useSelector((state) => state.products.products)

    const dispatch = useDispatch()

    const [t, setT] = useState([])

    useEffect(() => {
        if (!products || products.length === 0) {
            dispatch(getProducts())
        } else {
            let tmp = [...products]
            
            tmp.push(
                {
                    ID : 0,
                    active : true,
                    article : "123a",
                    comment : "Comms",
                    preview : "ksij9u3l4sfz3507zusdw6c94m3q1xop.jpg",
                    title : "None",
                    updated : "2024-09-02T10:51:48Z"
                }
            )
                setT(tmp)
        }
    }, [products, dispatch])

    if (!products || products.length === 0) {
        return <Preloader />
    }

    const handlerChange = (nid) => {
        let data = {
            linkID: link ? link.ID : 0,
            companyProductID: pid,
            brandProductID: parseInt(nid)
        }
        dispatch(ChangeLinked(id, cid, data))
    }

    return (
        <>
            {t.map((e, i) => <LinkerRow key={i} data={e} link={link} handler={handlerChange} />)}
        </>
    )
}

const LinkerRow = ({data, link, handler}) => {
    const [l, setL] = useState(false)

    useEffect(() => {
        setL(link && link.BProductID === data.ID)
    }, [link, data])

    return (
        <div>
            <input type="radio"
                    id={`p_${data.ID}`} 
                    name="link" 
                    value={`${data.ID}`}
                    onChange={(e) => handler(e.target.value)} 
                    checked={l} />
            <label htmlFor={`p_${data.ID}`}>{data.title}</label>
        </div>
    )
}