import { useEffect, useState } from "react";
import ModalFS from "../../../../components/Modal/fullScreen";
import { useDispatch, useSelector } from "react-redux";
import { createUserProfile, getCompanyUsers, getProfileRoles, updateCompanyProfile, updateUserProfile } from "../../../../store/SettingsStore/profile";

const CompanySettings = () => {
    const user = useSelector((state) => state.auth.user)
    
    const dispatch = useDispatch()

    const [name, setName] = useState(user.company.name);

    const handlerSubmit = (e) => {
        e.preventDefault();
        dispatch(updateCompanyProfile({name}))
    }

    return (
        <>
            <div style={{maxWidth: 500, marginBottom: 60}}>
                <h3>Настройки компании</h3>
                <form onSubmit={handlerSubmit}>
                    <div className="form-row">
                        <label htmlFor="name">
                                <div className="form-label">Название компании</div>
                                <input id="name"
                                    name="name"
                                    type="text"
                                    required
                                    className="text-field"
                                    value={name}
                                    onChange={e => setName(e.target.value)} />
                        </label>
                    </div>
                    <div>
                        <button className="button">Сохранить</button>
                    </div>
                </form>
            </div>
            <CompanyManagers />
        </>
    )
}

export default CompanySettings;

const CompanyManagers = () => {

    const users = useSelector((state) => state.profile.users)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCompanyUsers())
    }, [dispatch])

    const [s, setS] = useState(false);
    const [user, setUser] = useState(null)

    const handlerOpen = (data) => {
        if (data === null) {
            setUser(
                {
                    id: 0,
                    name: '',
                    phone: '',
                    email: '',
                    roles: [],
                    active: false
                }
            )
        } else {
            setUser(data)
        }

        setS(true)
    }   

    const handlerClose = () => {
        setS(false)
        setUser(null)
    }

    return(
       <div>
           <h3>Сотрудники</h3>
           <div>
                <table className="table mb-24">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Телефон</th>
                            <th>Почта</th>
                            <th>Роли</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((e, i) => <Row key={i} data={e} handler={handlerOpen} />)}
                    </tbody>
                </table>
            </div>

            <div>
                <button className="button" onClick={() => handlerOpen(null)}>Добавить</button>
            </div>

            {s ? <ModalFS close={handlerClose} body={<EditUser user={user} />} /> : null}
       </div>
   )
}

const Row = ({data, handler}) => {
    return (
        <tr onClick={() => handler(data)}>
            <td>{data.name}</td>
            <td>{data.phone}</td>
            <td>{data.email}</td>
            <td>{data.roles.map((e, i) => <div key={i}>{e.title}</div>)}</td>
            <td><div className={data.active ? "dot final" : "dot red"}></div></td>
        </tr>
    )
}

const EditUser = ({user}) => {

    const rolesAll = useSelector((state) => state.profile.roles)
    const dispatch = useDispatch()

    const [name, setName] = useState(user.name);
    const [mail, setMail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [password, setPassword] = useState('');
    const [active, setActive] = useState(user.active);
    const [roles, setRoles] = useState(user.roles);

    useEffect(() => {
        if (rolesAll.length === 0) {
            dispatch(getProfileRoles())
        }
    }, [rolesAll, dispatch])

    const handlerSubmit = (e) => {
        e.preventDefault();

        if (user.ID) {
            dispatch(updateUserProfile(user.ID, 
                {
                    name: name,
                    email: mail,
                    phone: phone,
                    password: password,
                    active: active,
                    roles: rolesID(roles)
                }, false
            ))
        } else {
            dispatch(createUserProfile( 
                {
                    name: name,
                    email: mail,
                    phone: phone,
                    password: password,
                    active: active,
                    roles: rolesID(roles)
                }
            ))
        }

    }

    const rolesID = (roles) => {
        var rs = []

        roles.forEach(element => {
            rs.push(element.ID)
        });

        return rs;
    }

    const handlerRoles = (a, id) => {
        let tmp = [...roles];
        if(a) {
            tmp.push(rolesAll.find(e => e.ID === id));
        } else {
            tmp = tmp.filter(e => e.ID!== id);
        }

        setRoles(tmp);
    }

    return (
        <div style={{maxWidth: 500}}>
            <h3>Настройки профиля</h3>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="name">
                            <div className="form-label">Имя</div>
                            <input id="name"
                                name="name"
                                type="text"
                                required
                                className="text-field"
                                value={name}
                                onChange={e => setName(e.target.value)} />
                    </label>
                </div>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <label htmlFor={"active"}>
                        <input 
                            id={"active"} 
                            checked={active}
                            onChange={e => setActive(e.target.checked)} 
                            type="checkbox"/>
                        Активен
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="mail">
                            <div className="form-label">Электронная почта</div>
                            <input id="mail"
                                name="mail"
                                type="email"
                                required
                                className="text-field"
                                value={mail}
                                onChange={e => setMail(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="phone">
                            <div className="form-label">Телефон (10 цифр)</div>
                            <input id="phone"
                                name="phone"
                                type="tel"
                                pattern="[0-9]{10}" 
                                required
                                className="text-field"
                                value={phone}
                                onChange={e => setPhone(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="password">
                            <div className="form-label">Новый пароль</div>
                            <input id="password"
                                name="password"
                                type="tex"
                                className="text-field"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                    </label>
                </div>
                <div style={{marginBottom: 10}}>
                    {rolesAll.map((e, i) => <Role key={i} role={e} userRoles={roles} handler={handlerRoles} /> ) }
                </div>
                <div>
                    <button className="button">Сохранить</button>
                </div>
            </form>
        </div>
    )
}

const Role = ({role, userRoles, handler}) => {
    const [a, setA] = useState(false);

    useEffect(() => {
        userRoles.forEach(element => {
            if (element.ID === role.ID) {
                setA(true);
            }
        });
    }, [role, userRoles])

    const handlerClick = () => {
        setA(!a);
        handler(!a, role.ID);

    }

    return (
        <div>
            <label htmlFor={role.code}>
                <input 
                    id={role.code} 
                    checked={a}
                    onChange={() => handlerClick()} 
                    type="checkbox"/>
                {role.title}
            </label>
        </div>
    )
}