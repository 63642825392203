import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const auth = createSlice({
    name: 'auth',
    initialState: {
        auth: false,
        check: false,
        user: null,
        token: null,
    },
    reducers: {
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setCheck: (state, action) => {
            state.check = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
    }
})

export const { setAuth, setCheck, setUser, setToken } = auth.actions

export const checkAuth = () => (dispatch, getState) => {
    let userState = getState().auth.user
    let userLocal = getLocalStorage("user")
    let tokenState = getState().auth.token
    let tokenLocal = getLocalStorage("token")

    if (userState === null && (userLocal === null || typeof userLocal === "undefined")) {
        dispatch(setCheck(true))
        dispatch(setAuth(false))
        return
    }

    if (tokenState === null && (tokenLocal === null || typeof tokenLocal === "undefined")) {
        dispatch(setCheck(true))
        dispatch(setAuth(false))
        return
    }

    let now = new Date()
    let expToken = JSON.parse(atob(tokenLocal.split('.')[1]))

    if (now.getTime() > expToken.exp) {
        dispatch(refreshToken())
    }
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

const getLocalStorage = (key) => {
    const value = localStorage.getItem(key)
    if (value === "" || value === "null" || value === "undefind") return null
    return value
}

const okLogin = data => dispatch => {
    setLocalStorage('user', JSON.stringify(data.user))
    setLocalStorage('token', data.token)
    dispatch(setUser(data.user))
    dispatch(setToken(data.token))
    dispatch(setAuth(true))
    dispatch(setCheck(true))
}

export const loginStore = (data, navigate, path) => dispatch => {
    let config = {
        method: 'post',
        url: `${gatewayURL}/auth/v1/b/login`,
        headers: { 
            'Content-Type': 'application/json', 
        },
        data : data
    };

    axios.request(config)
        .then((response) => {
            dispatch(okLogin(response.data.result))

            if (path === "/login") {
                navigate("/app")
            }
        })
        .catch((error) => {
            let err = "Ошибка авторизации"

            if (typeof error.response.data.result != 'undefined') {
                err = error.response.data.result
            }

            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: err, 
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

const refreshToken = () => dispatch => {
    let config = {
        method: 'post',
        url: `${gatewayURL}/auth/v1/b/refresh`,
        headers: { 
            'Content-Type': 'application/json', 
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(okLogin(response.data.result))
        })
        .catch((error) => {
            dispatch(logOut())
        });
}

export const logOut = () => dispatch => {
    setLocalStorage('user', null)
    setLocalStorage('token', null)
    dispatch(setUser(null))
    dispatch(setToken(null))
    dispatch(setAuth(false))
    dispatch(setCheck(true))
}

export default auth.reducer