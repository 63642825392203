import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import CompanySettings from "./CompanySettings"
import UserSettings from "./UserSettings"
import BrandData from "./BrandData"

const ProfileSettings = () => {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth.user)

    const [o, setO] = useState(false)
    const [m, setM] = useState("user")

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Профиль'))
        dispatch(setPageTitleBack({name: 'Настройки', link: `/app/settings`}))
    }, [dispatch])

    useEffect(() => {
        if (user) {
            user.roles.forEach(element => {
                if (element.code === 'Owner') {
                    setO(true)
                }
            });
        }
    }, [user])

    const ownerMenuHandler = (item) => {
        setM(item)
    }

    return (
        <>
            <OwnerBlock owner={o} selected={m} handler={ownerMenuHandler} />
            {m === "user" ? <UserSettings /> : null}
            {m === "company" ? <CompanySettings /> : null}
            {m === "brand" ? <BrandData /> : null}
        </>
    )
}

export default ProfileSettings

const OwnerBlock = ({owner, selected, handler}) => {
    const menu = [
        {
            label: 'Профиль',
            value: 'user'
        },
        {
            label: 'Компания',
            value: 'company'
        },
        {
            label: 'Бренд',
            value: 'brand'
        }
    ]
    if (owner) {
        return (
            <div>
                <ul className="line-menu">
                    {menu.map((item) => <li key={item.value} className={item.value === selected ? "active" : ""} onClick={() => handler(item.value)}>{item.label}</li>)}
                </ul>
            </div>
        )
    }
}