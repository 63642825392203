import { configureStore } from '@reduxjs/toolkit'

import auth from './AuthStore/auth'
import pageTitle from './NavStore/title'
import messages from './System/messages'
import profile from './SettingsStore/profile'
import products from './ProductsStore/products'
import sellers from './SettingsStore/sellers'
import seller from './SettingsStore/seller'

export default configureStore({
	reducer: {
        auth,
        pageTitle,
        messages,
        profile,
        products,
        sellers,
        seller
    },
})