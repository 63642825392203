import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const sellers = createSlice({
    name: 'sellers',
    initialState: {
        sellers: [],
        unlinked: []
    },
    reducers: {
        setSellers: (state, action) => {
            state.sellers = action.payload
        },
        setUnlinked: (state, action) => {
            state.unlinked = action.payload
        }
    }
})

export const { setSellers, setUnlinked } = sellers.actions

export const getSellers = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result.sellers) {
                dispatch(setSellers(response.data.result.sellers))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const createSeller = (data, navigate) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                navigate(`${response.data.result.ID}`)
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getUnlinked = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/unlinked`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result.unlinkedData) {
                dispatch(setUnlinked(response.data.result.unlinkedData))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default sellers.reducer