import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrand } from "../../../../store/SettingsStore/profile";

const BrandData = () => {

    const cerasusBrand = useSelector((state) => state.profile.cerasusBrand)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBrand())
    }, [dispatch])

    if (!cerasusBrand) {
        return (
            <div>Бренд не привязан</div>
        )
    }

    return (
        <div>
            <ul>
                <li>Бренд: {cerasusBrand.name}</li>
                <li>{cerasusBrand.logo ? <img src={cerasusBrand.logo} alt="logo" /> : ""}</li>
                <li>URL: {cerasusBrand.url}</li>
                <li>{cerasusBrand.description}</li>
            </ul>
        </div>
    )
}

export default BrandData;