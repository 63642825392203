import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const products = createSlice({
    name: 'products',
    initialState: {
        products: [],
        product: null,
        price: null
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        },
        setProduct: (state, action) => {
            state.product = action.payload
        },
        setPrice: (state, action) => {
            state.price = action.payload
        },
    }
})

export const { setProducts, setProduct, setPrice } = products.actions

export const getProducts = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result.products) {
                dispatch(setProducts(response.data.result.products))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const createProducts = (data, navigate) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                navigate(`${response.data.result.product.ID}`)
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getProduct = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setProduct(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateProduct = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/b/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getPrice = (pid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/products/${pid}/price/active`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setPrice(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setPrice(null))
        });
}

export const setNewPrice = (pid, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/products/${pid}/price`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(getPrice(pid))

                dispatch(updateMessagesList(
                    {
                        message: "ok",
                        type: "ok",
                        time: t.getTime()
                    }
                ))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setPrice(null))
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default products.reducer