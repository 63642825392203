import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const seller = createSlice({
    name: 'seller',
    initialState: {
        seller: null,
        linked: [],
        linkedProducts: [],
        showOnlyUnlinked: false,
    },
    reducers: {
        setSeller: (state, action) => {
            state.seller = action.payload
        }, 
        setLinked: (state, action) => {
            state.linked = action.payload
        },
        setLinkedProducts: (state, action) => {
            state.linkedProducts = action.payload
        },
        setShowOnlyUnlinked: (state, action) => {
            state.showOnlyUnlinked = action.payload
        }
    }
})

export const { setSeller, setLinked, setLinkedProducts, setShowOnlyUnlinked } = seller.actions

export const getSeller = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setSeller(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getSellerLinked = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/linked`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setLinked(response.data.result.companies))
            }
        })
        .catch((error) => {
            console.log(error);
            
        });
}

export const GetLinkedProducts = (id, cid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/linked/${cid}/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setLinkedProducts(response.data.result.products))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setLinkedProducts([]))
        });
}

export const ChangeLinked = (id , cid, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/linked/${cid}/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                console.log(response.data.result)
                dispatch(GetLinkedProducts(id, cid))
                dispatch(updateMessagesList(
                    {
                        message: "ok",
                        type: "ok",
                        time: t.getTime()
                    }
                ))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "err",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}


export default seller.reducer