import { useEffect } from "react";
import "./style.css"
import Cross from "../../assets/images/cross.svg"

const ModalFS = ({close, body}) => {
    useEffect(() => {
        const b = document.body;
        b.classList.add('noscroll');
    }, [])

    useEffect(() => {
        return () => {
            const b = document.body;
            b.classList.remove('noscroll');
        }
    }, [])
    return (
        <div className="fs-modal-wrapper">
            <div className="fs-modal-body">
                <div className="fs-modal-body-in">
                    <div onClick={close} 
                            className="fs-modal-close" 
                            style={{backgroundImage: `url(${Cross})`}}>
                    </div>
                    {body}
                </div>
            </div>
        </div>
    )
}

export default ModalFS