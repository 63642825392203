import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate, useParams } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import { getSeller, getSellerLinked, setLinked, setSeller } from "../../../../store/SettingsStore/seller"

const SellerPage = () => {

    const {id} = useParams()

    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Продавцы'))
        dispatch(setPageTitleBack({name: 'Продавцы', link: `/app/settings/sellers`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getSeller(id))
        dispatch(getSellerLinked(id))
    },[dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setSeller(null))
            dispatch(setLinked([]))
        }
    }, [dispatch])

    if (!seller) {
        return <Preloader />
    }

    return(
        <div>
            <CompanyInfo seller={seller} />
            <LinkedCompanies />
        </div>
    );
}

export default SellerPage

const CompanyInfo = ({seller}) => {
    return(
        <div>
            <h2>Контактная информация</h2>
            <h3>{seller.name}</h3>
            <div>{seller.person}</div>
            <div>{seller.phone}</div>
            <div>{seller.email}</div>
            <div>{seller.comment}</div>
        </div>
    );
}

const LinkedCompanies = () => {
    const linked = useSelector((state) => state.seller.linked)

    return (
        <div>
            <h2>Связанные компании</h2>
            <LinkedList linked={linked} />
        </div>
    )
}

const LinkedList = ({linked}) => {
    const navigate = useNavigate()

    if (linked.length === 0) {
        return (
            <div>Not</div>
        )
    }
    return (
        <div>
            <ul>
                {linked.map(e => <li key={e.ID} onClick={() => navigate(`c/${e.ID}`)}><span className="link">{e.name}</span></li>)}
            </ul>
        </div>
    )
}