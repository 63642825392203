import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate } from "react-router-dom"
import Items from "../../../../assets/images/items.svg"
import User from "../../../../assets/images/user.svg"

const MainSettings = () => {

    const dispatch = useDispatch()

    const settings = [
        {
            setting : "products",
            title : "Продукты",
            icon: Items
        },
        {
            setting : "sellers",
            title : "Продавцы",
            icon: User
        },
        {
            setting : "profile",
            title : "Профиль",
            icon: User
        }
    ]

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(null))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app`}))
    }, [dispatch])

    return (
        <div className="flex" style={{flexWrap: 'wrap', margin: -10}}>
        {settings.map((e, i) => <SettingBlock key={i} data={e} />)}
        </div>
    )
}

export default MainSettings

const SettingBlock = ({data}) => {
    const navigate = useNavigate()
    return (
        <div className="settings-block linked"
            onClick={() => navigate(`${data.setting}`)}>
                <div style={{backgroundImage: `url(${data.icon})`}}>
                    <h3>{data.title}</h3>
                </div>
        </div>
    )
}