import { useState } from "react"
import Logo from "../../../assets/images/logo-full.svg"
import "../style.css"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { loginStore } from "../../../store/AuthStore/auth"

const LoginPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    let loc = useLocation()

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    
    const handlerSubmit = (e) => {
        e.preventDefault();
        dispatch(loginStore({email, password: pass}, navigate, loc.pathname))
    }

    return (
        <div className="page-center-align-v">
            <div className="page-center-align">
                <div>
                    <span onClick={() => navigate("/")} 
                            className="link">На сайт</span>
                </div>
                <img src={Logo}
                    style={{width: 300}}
                    alt="Cerasus" />

                <h1>Войти</h1>
                <form style={{width: 300, marginBottom: 24}} onSubmit={handlerSubmit}>
                    <div className="form-row">
                        <label htmlFor="email">
                            <div className="form-label">Эл. почта</div>
                            <input id="email"
                                    name="email"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row" style={{marginBottom: 18}}>
                        <label htmlFor="password">
                            <div className="form-label">Пароль</div>
                            <input id="password"
                                    name="password"
                                    type="password"
                                    className="text-field"
                                    required
                                    value={pass}
                                    onChange={e => setPass(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <button style={{width: '100%'}} className="button">Войти</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage